import styled from '@emotion/styled';

export const SectionContentWrapper = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: ${props =>
    props.noHeadingIcon ? '-100px auto 0 auto' : '0 auto 20px auto'};

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: 40px;
  }
`;

export const Title = styled.h1`
  position: relative;
  text-align: center;
  margin: 0px;
  padding-bottom: 20px;
  font-size: 40px;

  &:after {
    content: '';
    position: absolute;
    width: 50%;
    max-width: 225px;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -5px;
    height: 2px;
    background: ${props => props.theme.colors.primary.base};
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 30px 0px;
    font-size: 32px;
  }
`;

export const SubContent = styled.div`
  padding: 0 20px;
  font-size: 18px;
  line-height: 28px;
  margin: 40px 0 0 0;
  color: ${props => props.theme.colors.black.base};
  text-align: center;
`;

export const Separator = styled.div`
  width: 80px;
  height: 3px;
  background-color: ${props => props.theme.colors.primary.base};
`;
