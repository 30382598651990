import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import PageHero from '@components/hero';
import Heading from '@components/pageHeading';
import { SectionHeading } from '@components/Common';
import { Layout, Container } from 'layouts';

// Assets
import headerImage from '@assets/services/android-dev/angroid-header.jpg';

import CompetitivePricingIcon from '@assets/services/android-dev/competitive-pricing.svg';
import InnovativeAppIcon from '@assets/services/android-dev/innovative-app.svg';
import WidgetIotIcon from '@assets/services/android-dev/widget-iot.svg';
import TechSupportIcon from '@assets/services/android-dev/tech-support.svg';
import SkilledTeamIcon from '@assets/services/android-dev/skilled-team.svg';

import DiscoveryIcon from '@assets/services/android-dev/discovery.svg';
import UIUXIcon from '@assets/services/android-dev/ui-ux.svg';
import ArchitectureIcon from '@assets/services/android-dev/architecture.svg';
import QAIcon from '@assets/services/android-dev/qa.svg';
import PlayStoreIcon from '@assets/services/android-dev/play-store-submission.svg';
import PostLaunchIcon from '@assets/services/android-dev/post-launch.svg';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 30px;
`;

const IconElem = styled.div`
  width: 200px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: start;
  flex-flow: column;
  margin: 15px 20px;
  .process-section {
    width: 200px;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 180px;
  }
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 14px;
`;

const ProcessWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.lighter};
`;

const ProcessIconElem = styled(IconElem)`
  width: 150px;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 90px;
  }
  svg {
    margin-bottom: 20px;
  }
`;

const Dash = styled.div`
  height: 2px;
  width: 70px;
  position: relative;
  top: 40px;
  background-color: ${props => props.theme.colors.primary.base};
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

const PointsWrapper = styled.div`
  width: 70%;
  //max-width: 400px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 20px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }
`;

const Point = styled.div`
  font-size: 14px;
  margin: 5px;
  font-weight: 600;
  width: 40%;
  &::before {
    content: ' ';
    width: 10px;
    height: 10px;
    display: inline-flex;
    margin-right: 15px;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary.base};
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
  }
`;

const AndroidApp = () => {
  return (
    <Layout>
      <Helmet title={'Android'} />
      <PageHero image={headerImage} />
      <Heading title="Android App Development" noHeadingIcon={true}>
        <p>
          Android is the next big thing, uh, oops, we're sorry, Android IS the
          big thing right now. 85% of smartphones globally run on Android. And
          believe us, it is not even the saturation point right now. So you can
          imagine how huge Android is in today's time.
        </p>
        <p>
          There's no better platform to get your app out other than it. And now,
          since you've stumbled upon us, let us tell you, we are experts in the
          field of Android development. We build apps that are seamless,
          flawless and successful. We bring together smart business strategies,
          captivating UI/UX design and a robust tech team. Our development team
          is backed by powerful agile methodologies and rapid launch marketing.
          Our Android app developers are quite well versed in developing an
          overabundance of Android apps of varied domains.
        </p>
      </Heading>
      <Container>
        <SectionHeading>We provide</SectionHeading>
        <IconWrapper>
          <IconElem>
            <CompetitivePricingIcon />
            <Label>Competitive Pricing</Label>
          </IconElem>
          <IconElem>
            <InnovativeAppIcon />
            <Label>Innovative App Concepts</Label>
          </IconElem>
          <IconElem>
            <WidgetIotIcon />
            <Label>Android widget and IoT development</Label>
          </IconElem>
          <IconElem>
            <TechSupportIcon />
            <Label>Consistent technical support</Label>
          </IconElem>
          <IconElem>
            <SkilledTeamIcon />
            <Label>A skilled team of developers support</Label>
          </IconElem>
        </IconWrapper>
      </Container>
      <ProcessWrapper>
        <Container>
          <SectionHeading>Our working process</SectionHeading>
          <IconWrapper>
            <ProcessIconElem>
              <DiscoveryIcon />
              <Label>Discovery</Label>
            </ProcessIconElem>
            <Dash />
            <ProcessIconElem>
              <UIUXIcon />
              <Label>UI/UX</Label>
            </ProcessIconElem>
            <Dash />
            <ProcessIconElem>
              <ArchitectureIcon />
              <Label>Architecture</Label>
            </ProcessIconElem>
            <Dash />
            <ProcessIconElem>
              <UIUXIcon />
              <Label>Development</Label>
            </ProcessIconElem>
            <Dash />
            <ProcessIconElem>
              <QAIcon />
              <Label>Quality Assurance</Label>
            </ProcessIconElem>
            <Dash />
            <ProcessIconElem>
              <PlayStoreIcon />
              <Label>Play Store Submission</Label>
            </ProcessIconElem>
            <Dash />
            <ProcessIconElem>
              <PostLaunchIcon />
              <Label>Post Launch</Label>
            </ProcessIconElem>
          </IconWrapper>
        </Container>
      </ProcessWrapper>
      <Container>
        <SectionHeading>Our integration game is strong</SectionHeading>
        <PointsWrapper>
          <Point>Beacon</Point>
          <Point>GPS</Point>
          <Point>Porting and Migration</Point>
          <Point>MDM</Point>
          <Point>Payment Gateway</Point>
          <Point>XMPP Chat Server</Point>
          <Point>Wearable</Point>
          <Point>Third-party</Point>
          <Point>Audio/Video Streaming</Point>
          <Point>Custom API</Point>
        </PointsWrapper>
      </Container>
    </Layout>
  );
};

export default AndroidApp;
