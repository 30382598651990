import React from 'react';
import { SectionContentWrapper, Title, SubContent } from './style';
import { Container } from '@layouts';

const SectionContent = props => {
  let overviewParagraphs = props.overview ? props.overview.split('\n') : null;
  return (
    <Container>
      <SectionContentWrapper noHeadingIcon={props.noHeadingIcon}>
        {props.title ? <Title>{props.title}</Title> : null}
        {overviewParagraphs &&
          overviewParagraphs.map((paragraph, idx) => (
            <SubContent key={idx}>{paragraph}</SubContent>
          ))}
        {props.children && <SubContent>{props.children}</SubContent>}
      </SectionContentWrapper>
    </Container>
  );
};

export default SectionContent;
